var render = function render(){var _vm=this,_c=_vm._self._c;return _c('common',{attrs:{"title":"我的信息"}},[_c('div',{staticClass:"myInformation"},[_c('div',{staticClass:"submit-t"},[_c('div',{staticClass:"submit-content"},[_c('div',{staticClass:"label"},[_vm._v("姓名：")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.userInfo.userName),expression:"userInfo.userName",modifiers:{"trim":true}}],attrs:{"type":"text"},domProps:{"value":(_vm.userInfo.userName)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userInfo, "userName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"submit-content"},[_c('div',{staticClass:"label"},[_vm._v("昵称：")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.userInfo.nickname),expression:"userInfo.nickname",modifiers:{"trim":true}}],attrs:{"type":"text"},domProps:{"value":(_vm.userInfo.nickname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.userInfo, "nickname", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"submit-content"},[_c('div',{staticClass:"label"},[_vm._v("性别：")]),_c('div',{staticClass:"radio"},_vm._l((_vm.sexOptions),function(item,index){return _c('div',{key:index,staticClass:"radio-sex",on:{"click":function($event){return _vm.handelCheckSex(item)}}},[_c('img',{attrs:{"src":item.isSelected
                  ? require('./img/radio-check.png')
                  : require('./img/radio.png'),"alt":""}}),_c('span',[_vm._v(_vm._s(item.label))])])}),0)]),_c('div',{staticClass:"submit-content"},[_c('div',{staticClass:"label"},[_vm._v("党员：")]),_c('div',{staticClass:"radio"},_vm._l((_vm.isPartyMemberOps),function(item,index){return _c('div',{key:index,staticClass:"radio-party",on:{"click":function($event){return _vm.handelCheckParty(item)}}},[_c('img',{attrs:{"src":item.isSelected
                  ? require('./img/radio-check.png')
                  : require('./img/radio.png'),"alt":""}}),_c('span',[_vm._v(_vm._s(item.label))])])}),0)]),_c('div',{staticClass:"submit-content",on:{"click":function($event){_vm.isShowCas = true}}},[_c('div',{staticClass:"label"},[_vm._v("房号：")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.houseName),expression:"houseName"}],attrs:{"type":"text","readonly":""},domProps:{"value":(_vm.houseName)},on:{"input":function($event){if($event.target.composing)return;_vm.houseName=$event.target.value}}})])]),_c('div',{staticClass:"submit-b"},[_c('div',{class:[
          'btn',
          'confirm',
          {
            gray:
              _vm.userInfo.registerType == 1 || _vm.userInfo.realNameAuditStatus == 1,
          },
        ],on:{"click":function($event){return _vm.goRealNameAuth(_vm.userInfo.registerType)}}},[_vm._v(" 去实名认证 ")]),_c('div',{staticClass:"btn cancel",on:{"click":_vm.saveInfo}},[_vm._v("保存")])])]),_c('div',{staticClass:"popup"},[_c('van-popup',{attrs:{"round":"","position":"bottom"},model:{value:(_vm.isShowCas),callback:function ($$v) {_vm.isShowCas=$$v},expression:"isShowCas"}},[_c('van-cascader',{attrs:{"field-names":_vm.fieldNames,"title":"请选择所在房号","active-color":"#1989fa","options":_vm.areaItemList},on:{"change":_vm.change,"close":function($event){_vm.isShowCas = false},"finish":_vm.onFinish},model:{value:(_vm.userInfo.houseId),callback:function ($$v) {_vm.$set(_vm.userInfo, "houseId", $$v)},expression:"userInfo.houseId"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }