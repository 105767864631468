// 获取所有的脸用户数据
const getMyUserInfo = `/gateway/hc-mini/user/aio/getMyUserInfo`;
//获取区级联动接口
const areaLinkUrl = "/gateway/hc-space/space/getSpaceTreeList";
//完善信息
const changeMyUserInfo = "/gateway/hc-mini/user/aio/changeMyUserInfo";
//获取我的信息
const getUserInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
export {
  getMyUserInfo,
  areaLinkUrl,
  changeMyUserInfo,
  getUserInfoUrl
};
