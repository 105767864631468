<template>
  <common title="我的信息">
    <div class="myInformation">
      <div class="submit-t">
        <div class="submit-content">
          <div class="label">姓名：</div>
          <input type="text" v-model.trim="userInfo.userName" />
        </div>
        <div class="submit-content">
          <div class="label">昵称：</div>
          <input type="text" v-model.trim="userInfo.nickname" />
        </div>
        <div class="submit-content">
          <div class="label">性别：</div>
          <div class="radio">
            <div
              class="radio-sex"
              v-for="(item, index) in sexOptions"
              :key="index"
              @click="handelCheckSex(item)"
            >
              <img
                :src="
                  item.isSelected
                    ? require('./img/radio-check.png')
                    : require('./img/radio.png')
                "
                alt=""
              />
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
        <div class="submit-content">
          <div class="label">党员：</div>
          <div class="radio">
            <div
              class="radio-party"
              v-for="(item, index) in isPartyMemberOps"
              :key="index"
              @click="handelCheckParty(item)"
            >
              <img
                :src="
                  item.isSelected
                    ? require('./img/radio-check.png')
                    : require('./img/radio.png')
                "
                alt=""
              />
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
        <div class="submit-content" @click="isShowCas = true">
          <div class="label">房号：</div>
          <input type="text" readonly v-model="houseName" />
        </div>
      </div>

      <div class="submit-b">
        <div
          :class="[
            'btn',
            'confirm',
            {
              gray:
                userInfo.registerType == 1 || userInfo.realNameAuditStatus == 1,
            },
          ]"
          @click="goRealNameAuth(userInfo.registerType)"
        >
          去实名认证
        </div>
        <div class="btn cancel" @click="saveInfo">保存</div>
      </div>
    </div>

    <div class="popup">
      <van-popup v-model="isShowCas" round position="bottom">
        <van-cascader
          v-model="userInfo.houseId"
          :field-names="fieldNames"
          title="请选择所在房号"
          active-color="#1989fa"
          :options="areaItemList"
          @change="change"
          @close="isShowCas = false"
          @finish="onFinish"
        />
      </van-popup>
    </div>
  </common>
</template>

<script>
import storage from "../utils/storage";
import Vue from "vue";
import { Cascader, Popup } from "vant";
Vue.use(Cascader);
Vue.use(Popup);
import {
  getMyUserInfo,
  areaLinkUrl,
  changeMyUserInfo,
  getUserInfoUrl,
} from "./api.js";
import { mapState } from "vuex";
import common from "../components/common.vue";
export default {
  name: "myInformation",
  components: {
    common,
  },
  props: {},
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  data() {
    return {
      houseName: "",
      isShowCas: false, //控制选择房号弹窗
      areaItemList: [], //社区房号信息
      fieldNames: {
        text: "name",
        value: "id",
        children: "children",
      },
      //用户信息
      userInfo: {
        userName: "", //真实姓名
        nickname: "", //昵称
        userId: "",
        isPartyMember: "", //是否党员 0 是 1 否
        sex: "", //是否男女 1 男 2女
        houseId: "", //房间id
        userSpaceRelationId: "", //房屋绑定id
        registerType: "",
        realNameAuditStatus: -1,
      },
      //男女枚举数据
      sexOptions: [
        {
          value: 1,
          label: "男",
          isSelected: false,
        },
        {
          value: 2,
          label: "女",
          isSelected: false,
        },
      ],
      //是否党员枚举数据
      isPartyMemberOps: [
        {
          value: 1,
          label: "是",
          isSelected: false,
        },
        {
          value: 0,
          label: "否",
          isSelected: false,
        },
      ],
      hasChildren: false,
    };
  },

  created() {},
  mounted() {
    this.getMyUserInfo();
    this.getRoomList();
  },
  methods: {
    change(data) {
      if (data.selectedOptions && data.selectedOptions[data.tabIndex]) {
        if (!data.selectedOptions[data.tabIndex].hasChildren) {
          this.hasChildren = false;
        } else {
          this.hasChildren = true;
        }
      }
    },
    //校验字段
    verifyFields() {
      let result = true;
      if (!this.userInfo.userName) {
        this.$toast("请输入姓名");
        result = false;
        return;
      }
      if (!this.userInfo.nickname) {
        this.$toast("请输入昵称");
        result = false;
        return;
      }
      if (![1, 2].includes(this.userInfo.sex)) {
        this.$toast("请选择性别");
        result = false;
        return;
      }
      if ([null, undefined, ""].includes(this.userInfo.isPartyMember)) {
        this.$toast("请选择党员");
        result = false;
        return;
      }
      if (!this.userInfo.houseId) {
        this.$toast("请选择房号");
        result = false;
        return;
      }
      if (this.hasChildren) {
        this.$toast({ message: "请选择房号", duration: 5000 });
        result = false;
        return;
      }
      return result;
    },
    //完善信息
    saveInfo() {
      if (this.sexOptions.filter((el) => el.isSelected).length > 0) {
        this.userInfo.sex = this.sexOptions.filter((el) => el.isSelected)[0][
          "value"
        ];
      }
      if (this.isPartyMemberOps.filter((el) => el.isSelected).length > 0) {
        this.userInfo.isPartyMember = this.isPartyMemberOps.filter(
          (el) => el.isSelected
        )[0]["value"];
      }
      const result = this.verifyFields();
      if (result) {
        this.changeMyUserInfo();
      }
    },
    async changeMyUserInfo() {
      console.log("userInfo----->" + this.userInfo.houseId);
      console.log("userInfo----->" + this.userInfo.userSpaceRelationId);
      const res = await this.$axios.post(changeMyUserInfo, this.userInfo);
      if (res.code == 200) {
        this.$toast(res.msg);
        await this.updateUserInfoUrl();
        this.$router.back();
      }
    },
    //更新用户信息
    async updateUserInfoUrl() {
      const res = await this.$axios.get(getMyUserInfo, {
        params: {
          userId: this.userId,
        },
      });

      if (res.code == 200) {
        const { data } = res;
        storage.set("systemUserInfo", data);
        this.$store.commit("setUserId", data.userId);
        this.$store.commit("setHouseId", data.houseId);
        this.$store.commit("setTenantId", data.tenantId);
        this.$store.commit("setCommunityId", data.communityId);
      }
    },
    //去实名认证
    goRealNameAuth(type) {
      if (type == 1 || this.userInfo.realNameAuditStatus == 1) {
        this.$toast("已实名认证");
        return;
      }
      this.$router.push({
        name: "identityCardRegister",
        query: {
          isRegister: 3,
        },
      });
    },
    //勾选完
    onFinish({ selectedOptions }) {
      this.isShowCas = false;
      this.houseName = selectedOptions.map((option) => option.name).join("-");
    },
    //获取房号
    getRoomList() {
      this.$axios
        .get(`${areaLinkUrl}?specifyCollectionTypes=1&topSpaceId=P1C2A2S1D1`)
        .then((res) => {
          if (res.code == 200) {
            this.areaItemList = res.data;
          }
        });
    },
    //选择男女
    handelCheckSex(item) {
      this.sexOptions.forEach((item) => {
        item.isSelected = false;
      });
      item.isSelected = !item.isSelected;
    },
    //选择党员
    handelCheckParty(item) {
      this.isPartyMemberOps.forEach((item) => {
        item.isSelected = false;
      });
      item.isSelected = !item.isSelected;
    },
    //获取我的信息
    getMyUserInfo() {
      this.$axios
        .get(getMyUserInfo, {
          params: {
            userId: this.userId,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            Object.keys(this.userInfo).forEach((keys) => {
              this.userInfo[keys] = res.data[keys];
            });
            this.houseName = res.data.houseName;
            this.sexOptions.forEach((item) => {
              if (item.value == this.userInfo.sex) {
                item.isSelected = true;
              } else {
                item.isSelected = false;
              }
            });
            this.isPartyMemberOps.forEach((item) => {
              if (item.value == this.userInfo.isPartyMember) {
                item.isSelected = true;
                console.log("res----->");
              } else {
                item.isSelected = false;
              }
            });
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.myInformation {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 50px 256px 57px 331px;
  .submit-t {
    width: 100%;
    .submit-content {
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      input {
        flex: 1;
        height: 50px;
        border-radius: 6px;
        border: 1px solid #e4e7ed;
        font-weight: 400;
        font-size: 16px;
        color: #000;
        font-style: normal;
        padding: 0 15px;
        margin-left: 15px;
        box-sizing: border-box;
        &::placeholder {
          font-weight: 400;
          font-size: 16px;
          color: rgba(0, 0, 0, 0.5);
          font-style: normal;
        }
      }
      .label {
        width: 50px;
        white-space: nowrap;
        font-weight: 400;
        font-size: 16px;
        color: #2f3a4f;
        line-height: 22px;
        font-style: normal;
      }
      .radio {
        flex: 1;
        display: flex;
        align-items: center;
        margin-left: 15px;
        .radio-sex,
        .radio-party {
          display: flex;
          align-items: center;
          &:first-child {
            margin-right: 67px;
          }
          img {
            width: 21px;
            height: 21px;
            margin-right: 8px;
          }
          span {
            font-weight: 400;
            font-size: 16px;
            color: #2f3a4f;
            line-height: 22px;
            font-style: normal;
          }
        }
      }
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:nth-child(3) {
        margin-top: 29px;
        margin-bottom: 29px;
      }
      &:nth-child(4) {
        margin-bottom: 29px;
      }
    }
  }
  .submit-b {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    box-sizing: border-box;
    .btn {
      width: 239px;
      height: 50px;
      box-sizing: border-box;
      background: #1874fd;
      border-radius: 6px;
      // padding: 13px 102px;
      font-weight: 600;
      font-size: 18px;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      font-style: normal;
      &:last-child {
        margin-left: 138px;
        border: 1px solid #1874fd;
        background: #ffffff;
        color: #1874fd;
      }
      &.gray {
        background: #eaeaea;
        color: #ffffff;
      }
    }
  }
}
</style>
